import { Controller } from "@hotwired/stimulus"
import cookie from "cookie";

export default class extends Controller {
  close(event) {
    document.cookie = cookie.serialize('cookie_accept', 'true');

    var cookieAlert = document.querySelector(".cookiealert");
    cookieAlert.classList.remove("show");
  }
}
